.register-new-visitor-container {
    .register-new-visitor-title {
        color: #097844;
        font-weight: 600;
        font-size: 1.2rem;
        margin-bottom: 2rem;
    }

    .register-new-visitor-form-row-1 {
        display: flex;
        gap: 1rem;
    }
    .register-new-visitor-form-row-2 {
        display: flex;
        gap: 1rem;
        margin-top: 2rem;
    }
    .register-new-visitor-form-row-4 {
        display: flex;
        gap: 3rem;
        margin-top: 2rem;
        // margin: 2em;
    }
    .register-new-visitor-form-row-text-area {
        display: flex;
        gap: 3rem;
        margin-top: 2rem;
    }
    .register-visitor-btn-place {
        position: absolute;
        right: 10rem;
        display: flex;
        justify-content: end;
        margin-top: 2rem;
    }

    .register-visitor-btn-place 
    .docs-uploads{
        display : flex
        
    }
    
}