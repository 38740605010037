.tabs-container {
    width: 100%;
    text-align: center;
  }
  
  .tabs-header {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid #ddd;
    margin-bottom: 20px;
  }
  
  .tab-item {
    padding: 10px 20px;
    margin: 0 15px;
    font-size: 1.2rem;
    cursor: pointer;
    position: relative;
    color: #333;
  }
  
  .tab-item::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background-color: transparent;
    transition: background-color 0.3s ease;
  }
  
  .tab-item.active::after {
    background-color: darkgreen; /* Underline color */
  }
  
  .tab-item:hover {
    color: darkgreen;
  }
  
  .tabs-content {
    font-size: 1.2rem;
    margin-top: 20px;
  }
  