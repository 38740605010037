.App {
  display: flex;
  .drop-down-menus {
    height: auto;
    width: 10rem;
    background-color: #ECF2F7;
    position: fixed;
    right: 1rem;
    top: 4rem;
    border-radius: 0.2rem;
    z-index: 1000;
    div {
      padding: 1rem;
      img {
        width: 1.5rem;
        background-color: #282c34;
        font-weight: 600;
      }
      &:hover {
        background-color: #f3f3f3;
        cursor: pointer;
      }
    }
    :nth-child(2) {
      &:hover {
        font-weight: 600;
      }
    }
    box-shadow: 2px 2px 12px 2px silver;
  }
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
