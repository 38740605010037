.camera-management-container {
    display: flex;
    .visitor-side-panel-container {
        height: 95.3vh;
        width: 15rem;
        box-shadow: 0 0 10px rgba(128, 128, 128, 0.479);
        clip-path: inset(0px -15px 0px 0px);
        position: fixed;
        .critical-ai-log-bottom {
            position: absolute;
            bottom: 0;
            display: flex;
            width: 100%;
            img {
                // height: 3rem;
                width: 10rem;
                margin: 0 auto;
            }
        }
        
    }
    .visitor-content-container {
        width: 80%;
        margin-left: 15rem;
        padding: 1.5rem;
        position: fixed;
    }
    .visitor-side-panel-routes-container {
        .visitor-side-panel-route-box {
            background-color: #F3F8F6;
            cursor: pointer;
            margin-top: 0.8rem;
            font-size: 0.9rem;
            display: flex;
            color: rgb(65, 65, 65);
            .not-selected-side-route {
                width: 0.5rem;
                height: 2.7rem;
            }
            .selected-side-route {
                width: 0.5rem;
                height: 2.7rem;
                background-color: #097844;
                border-top-right-radius: 1rem;
                border-bottom-right-radius: 1rem;
            }
            .visitor-side-panel-route {
                padding: 0.8rem 1rem;
            }
            .selected-visitor-side-panel-route {
                padding: 0.8rem 1rem;
                color: black;
                font-weight: 600;
            }
            &:hover {
                background-color: #dae6e1;
                transition: 0.3s;
            }
        }
        .selected-visitor-side-panel-route-box {
            background-color: #09784433;
        }
    }

    .mentor-selected-border {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 0.3rem;
        background-color: rgba(9, 120, 68, 1);
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
}