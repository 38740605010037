$primary-color: #2c4b71;
$secondary-color: #4a6285;
$text-color: #e5ecfd;

.loader-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba($primary-color, 0.05);
  padding: 2rem;
  border-radius: 0.5rem;

  .loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin-bottom: 1rem;

    div {
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: $primary-color;
      animation: loader 1.2s linear infinite;

      &:nth-child(1) {
        top: 8px;
        left: 8px;
        animation-delay: 0s;
      }

      &:nth-child(2) {
        top: 8px;
        left: 32px;
        animation-delay: -0.4s;
      }

      &:nth-child(3) {
        top: 8px;
        left: 56px;
        animation-delay: -0.8s;
      }

      &:nth-child(4) {
        top: 32px;
        left: 8px;
        animation-delay: -0.4s;
      }

      &:nth-child(5) {
        top: 32px;
        left: 32px;
        animation-delay: -0.8s;
      }

      &:nth-child(6) {
        top: 32px;
        left: 56px;
        animation-delay: -1.2s;
      }

      &:nth-child(7) {
        top: 56px;
        left: 8px;
        animation-delay: -0.8s;
      }

      &:nth-child(8) {
        top: 56px;
        left: 32px;
        animation-delay: -1.2s;
      }

      &:nth-child(9) {
        top: 56px;
        left: 56px;
        animation-delay: -1.6s;
      }
    }
  }

  .loading-message {
    color: $primary-color;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    margin-top: 0.5rem;
    opacity: 0.8;
    animation: pulse 1.5s ease-in-out infinite;
  }
}

@keyframes loader {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
}

// Small size variant
.loader-container.small {
  padding: 1rem;

  .loader {
    width: 40px;
    height: 40px;

    div {
      width: 8px;
      height: 8px;

      &:nth-child(1) { top: 4px; left: 4px; }
      &:nth-child(2) { top: 4px; left: 16px; }
      &:nth-child(3) { top: 4px; left: 28px; }
      &:nth-child(4) { top: 16px; left: 4px; }
      &:nth-child(5) { top: 16px; left: 16px; }
      &:nth-child(6) { top: 16px; left: 28px; }
      &:nth-child(7) { top: 28px; left: 4px; }
      &:nth-child(8) { top: 28px; left: 16px; }
      &:nth-child(9) { top: 28px; left: 28px; }
    }
  }

  .loading-message {
    font-size: 0.875rem;
  }
}

// Inline variant
.loader-container.inline {
  background: none;
  padding: 0;

  .loader {
    margin: 0;
  }

  .loading-message {
    margin: 0;
  }
}