.dashboard__title {
    position: relative;
    display: inline-block;
    margin-left: 10px;
    color: rgba(102, 37, 73, 1);
  }
  
  .dashboard__title::after {
    content: "";
    display: block;
    width: 80%;
    height: 2px;
    background-color: rgba(0, 0, 0, 1);
    position: absolute;
    bottom: -5px;
    left: 0;
    border-radius: 30px;
  }
  
  .dashboard__cards {
    width: 20rem;
    height: 8rem;
    display: flex;
    border: 1px solid grey;
    padding: 1rem;
    border-radius: 20px;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  
  .dashboard__card-highlighter {
    position: absolute;
    width: 4px;
    height: 2.5rem;
    background-color: rgba(80, 150, 172, 1);
    left: 0;
    top: 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .dashboard__card-data {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .dashboard__cardData-number-count {
    color: rgba(9, 120, 68, 1);
    font-size: 2.5rem;
    margin: 0;
  }
  
  .dashboard__cardData-tile {
    margin: 0;
    position: relative;
  }
  
  .dashboard__cardData-tile::after {
    content: "";
    display: block;
    width: 80%;
    height: 2px;
    background-color: rgba(254, 216, 204, 1);
    position: absolute;
    bottom: -5px;
    left: 0;
    border-radius: 30px;
  }
  
  .dashboard__cardData-description {
    font-size: 0.6rem;
    color: grey;
  }
  
  .dashboard__cardData-image {
    /* width: 4rem; */
  }