.topics-container{


    .visitors-list-header {
        display: flex;
        justify-content: space-between;

        .visitors-list-header-left {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .visitors-list-header-title {
                font-size: 1.4rem;
                font-weight: 500;
            }
            .visitors-list-header-update {
                background-color: #f1fffa;
                height: auto;
                padding: 0.2rem 0.7rem;
                line-height: 1rem;
                margin: auto 0.5rem;
                border-radius: 1rem;
                font-size: 0.8rem;
                color: green;
            }
        }

    }
.subject-filter{
    display: flex;
    align-items: center;
    gap: 0.8rem;
}
.subject-filter .programs
                  {
    display: flex;
    align-items: center;
    gap: 0.4rem;
}
.programs .subject-tag{
    background: #fff;
    border: 1px solid;
    color: #000;
    padding: 0.4rem 1rem;
    border-radius: 0.4em;
    cursor: pointer;
    font-size: 1rem;
}
.programs .subject-tag.active{
    color: #fff;
    background: #02720d;
}

.topicContainer{
    margin-top: 2rem;
}
.topicContainer ul{
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: auto auto;
    gap: 0.8rem;
}
.topicContainer li{
    border-radius: 0.4rem;
    border: 0.81px solid #C5B3D5;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0.8rem;
}
.topicContainer .band{
    background: #0BAEDB;
    width: 2%;
    height: 4rem;
    border-radius: 0.4rem 0 0 0.4rem;
}
.topic-name{
    display: flex;
    gap: 0.4rem;
    width: 60%;
    height: 100%;
    align-items: center;
    text-align: left;
    justify-content: space-between;
}
.subject-color-code{
    height: inherit;
    width: 4%;
    border-radius: 0.4rem 0 0 0.4rem;
}

.add-plan{
    border-radius: 0.4rem;
    margin: 1rem;
    border: 1px solid #02720d;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 20%;
    height: 4rem;
    color: #02720d;

    .add-icon{
      border: 1px solid #02720d;
      border-radius: 0.4rem;
      padding: .2rem;
      cursor: pointer;
    }
  }
}
