.edit-camera-form-container {
    padding: 1rem 2rem;
    .add-camera-form-title {
        color: #097844;
        font-weight: 600;
        font-size: 1.2rem;
    }
    .add-camera-form-row-1, 
    .add-camera-form-row-2,
    .add-camera-form-row-3,
    .add-camera-form-row-4
    {
        display: flex;
        gap: 2rem;
        margin-top: 2rem;
    }
    .btn-position {
        display: flex;
        justify-content: end;
        margin: 2rem;
    }

    .algo-settings-container {
        margin-top: 1rem;
        table {
            width: 100%;
            border-collapse: collapse; 
            font-size: 0.9rem;
            
            thead {
                color: gray;
                font-weight: 600;
                border-bottom: 0.01rem solid rgb(206, 206, 206);
                background-color: #0978440D;
                text-align: left;
                th {
                    padding: 1.2rem 1rem;
                    text-align: left;
                }
            }
    
            tr {
                    border-bottom: 0.01rem solid rgb(207, 206, 206);
                    &:hover {
                        background-color: rgb(236, 247, 252);
                        cursor: pointer;
                        transition: ease-in-out 0.1s;
                    }
                td {
                    padding: 0.5rem 1rem;
                    color: rgb(82, 82, 82);
                    text-align: left;
                }
            }
        }
    }
}

.camera-success-pop-up {
    height: 60vh;
    width: 79%;
    background-color: white;
    position: absolute;
    top: 7rem;
    margin: auto;
    box-shadow: 2px 2px 8px 2px rgb(161, 161, 161);
    border-radius: 0.2rem;
    display: flex;
    .camera-success-pop-up-content {
        width: fit-content;
        margin: auto;
        .message {
            width: fit-content;;
            margin-bottom: 1rem;
        }
        .btn {
            width: fit-content;
            margin: auto;
        }
        .close-btn {
            width: fit-content;
            margin: auto;
            margin-top: 1rem;
            &:hover {
                cursor: pointer;
                color: blue;
            }
        }
    }
}