.add-question-page {
  width: 98%;
  margin: 0 auto;
  padding: 0.8rem;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;

    .back {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      cursor: pointer;
    }

    .switch-question {
      display: flex;
      align-items: center;
      gap: 0.8rem;

      p {
        background: rgb(201, 199, 199);
        padding: 0.4rem 0.8rem;
        border-radius: 0.4rem;
      }

      svg {
        cursor: pointer;
      }
    }

    .action {
      display: flex;
      align-items: center;
      gap: 0.8rem;

      span {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        cursor: pointer;
        padding: 0.2rem 1rem;
        border-radius: 0.4rem;

        &:nth-child(1) {
          color: #097844;
          border: 1px solid #097844;

        }

        &:nth-child(2) {
          color: #fff;
          background: #008d4b;
        }
      }
    }
  }

 



  .questionViewer-container {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;



    .contain {
      border: 1px solid #C5B3D5;
      border-radius: 0.4rem;

      .title {
        background: #ECE6F2;
        padding: 0.4rem;
      }

      .topics-conatiner {
        padding: 0.8rem;
        display: flex;
        flex-wrap: wrap;
        gap: 0.8rem;

        span {
          display: flex;
          align-items: center;
          gap: 0.2rem;
          font-size: 0.8rem;
          cursor: pointer;
          padding: 0.4rem 0.8rem;
          background: #ECE6F2;
          border-radius: 2rem;
        }
      }

      .tags-container {
        padding: 0.8rem;
        display: flex;
        flex-wrap: wrap;
        gap: 0.8rem;

        span {
          display: flex;
          align-items: center;
          gap: 0.2rem;
          font-size: 0.8rem;
          cursor: pointer;
          padding: 0.4rem 0.8rem;
          border: 1px solid black;
          border-radius: 2rem;
        }

        .selected {
          background: #ECE6F2;
          border: none;
        }
      }
    }
  }
}


.year-switch {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem;
}

.year {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.year div {
  background: #fff;
  border: none;
  color: #000;
  padding: 0.4rem 1rem;
  border-radius: 0.4em;
  cursor: pointer;
}

.year div:hover {
  background: #097844;
  color: #fff;
}

.year div.active {
  background: #097844;
  color: #fff;
}



.tags-section{
  padding: 1rem 0;
  border-radius: 0.4em;
  .accordion {
    border: 0.94px solid #ece6f2;
    box-shadow: 0px 0.94px 1.88px 0px #44097826;
    border-radius: 0.4rem;
    margin-top: 0.8rem;

    div {
      padding: 0.4rem 0.8rem ;
    }

    .tags-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.8rem;
    color: #440978;
    font-weight: 600;
    padding: 0.8rem;
    background: #ECE6F2;
    border: none;
    cursor: pointer;
    font-size: 0.8rem;
  }
   .explanation{
    display: flex;
    align-items: center;
    gap: 0.8rem;
    flex-wrap: wrap;

    span{
      display: flex;
    align-items: center;
    gap: 0.2rem;
    font-size: 0.8rem;
    cursor: pointer;
    padding: 0.4rem;
    background: #ECE6F2;
    border-radius: 2rem;
    }
  }

  .all-tags{
    display: flex;

    .unselected{
      background: none;
      border: 1px solid black;
    }
  }
  }
}
.topics-section{
  padding: 1rem 0;
  border-radius: 0.4em;
  .accordion {
    border: 0.94px solid #ece6f2;
    box-shadow: 0px 0.94px 1.88px 0px #44097826;
    border-radius: 0.4rem;
    margin-top: 0.8rem;

    div {
      padding: 0.4rem 0.8rem ;
    }

    .topic-title {
      background: #ECE6F2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.8rem;
    color: #440978;
    font-weight: 600;
    padding: 0.8rem;
    border: none;
    cursor: pointer;
    font-size: 0.8rem;
  }

  .explanation{
    display: flex;
    align-items: center;
    gap: 0.4rem;
    flex-wrap: wrap;

    span{
      display: flex;
    align-items: center;
    gap: 0.2rem;
    font-size: 0.8rem;
    cursor: pointer;
    padding: 0.4rem;
    background: #ECE6F2;
    border-radius: 2rem;
    }
  }

  .all-tags{
    display: flex;

    .unselected{
      background: none;
      border: 1px solid black;
    }
  }
  }
}