.quespaper-page {
  width: 98%;
  margin: 0 auto;
  border: 1px solid rgba(0, 0, 0, 0.102);
  border-radius: 0.4rem;
  margin-top: 1rem;
  padding: 1rem;

  .heading-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem;

    .title-time {
      display: flex;
      align-items: center;
      gap: 0.8rem;

      .time {
        color: #097844;
        font-size: 0.8rem;
      }
    }

    .filter-addButton {
      display: flex;
      align-items: center;
      gap: 1rem;

      .filter {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        cursor: pointer;

        img {
          width: 24%;
        }
      }

      .add-exam-button {
        background: #02720d;
        color: #fff;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.4rem 0.8rem;
        border-radius: 0.4rem;
        cursor: pointer;
      }
    }
  }

  .question-list-container {
    // border: 1px solid;
    height: 83vh;
    overflow-y: scroll;

    .question-container {
      display: flex;
      justify-content: space-between;
      padding: 0.8rem;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.102);
      border-radius: 0.4rem;
      margin-top: 0.8rem;

      .content {
        width: 70%;

        .tags {
          display: flex;
          align-items: center;
          gap: 0.8rem;
          margin-bottom: 0.8rem;

          span {
            font-size: 0.8rem;
          }
        }

        .question {
          font-size: 1.2rem;
        }
      }

      .view-button {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.4rem;
        font-size: 1.2rem;
      }
    }
  }

}