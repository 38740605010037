.visitor-details-container {
    display: flex;
    .pop-up-file {
        border: rgb(211, 211, 211) 0.001rem solid;
        border-radius: 0.7rem;
        margin: 1rem 0;
        padding: 1rem 1rem;
        .pop-up-file-title {
            // color: #0EA5E9;
            font-weight: 600;
            font-size: 1.2rem;
        }
        .pop-up-file-container {
            height: auto;
            width: auto;
            background-color: #E7F6FD;
            border-radius: 0.5rem;
            padding: 0.4rem 0 0.1rem 1rem;
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;
            .pop-up-file-details {
                display: flex;
                gap: 0.7rem;
            .pop-up-file-img {
                background-color: rgb(255, 255, 255);
                width: fit-content;
                padding: 0.2rem 0.5rem;
                height: fit-content;
                border-radius: 0.5rem;
                img {
                    margin-top: 0.3rem;
                    height: 2rem;
                    width: 2rem;
                }
            }
            .pop-up-file-name-size {
                font-size: small;
            }
            }
            .pop-up-file-name-download {
                margin: auto 1rem auto 0;
                img {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .visitor-details-left-panel {
        width: 40%;
        min-width: 25rem;
        height: auto;
        border: darkgreen 0.01rem solid;

        // box-shadow: 0 0 10px rgba(128, 128, 128, 0.479);
        clip-path: inset(0px -15px 0px 0px);
        padding: 1rem;
        .visitor-details-left-panel-back-btn {
            padding: 0 0 1rem 0;
            border-bottom: darkgreen solid 0.01rem;
        }
        .profile-image-name-section {
            display: flex;
            padding: 0.5rem;
            :first-child {
                padding: 0.5rem;
                // width: 50%;
                display: flex;
                align-items: center;
                // text-align: center;
                .profile-image {
                    height: 10rem;
                    width: 10rem;
                }
            }
            :nth-child(2) {
                
                height: auto;
            }

            .visitor-name {
                font-size: 1.3rem;
                font-weight: 600;
                margin-top: 1rem;
            }
            .visitor-status {
                color: #747474;
                .status-published {
                    background-color: #4D4D33;
                    border-radius: 1rem;
                    padding: 0 1rem;
                    color: white;
                }
                .status-blocked {
                    background-color: silver;
                    border-radius: 1rem;
                    padding: 0 1rem;
                    color: #7B0323;
                }
            }
        }
        .personal-details-section {
            border: darkgreen 0.01rem solid;
            border-radius: 1rem;
            overflow: hidden;
            margin: 1.5rem 0;
            .personal-details-section-header{
                background-color: #5096AC26;
                padding: 1rem;
                font-size: 1.2rem;
                font-weight: 600;
                display: flex;
                justify-content: space-between;

                .personal-details-section-header-btns {
                    display: flex;
                    gap: 1rem;
                }

            }
            .personal-details {
                padding: 1.5rem;
                .personal-details-div {
                    display: flex;
                    margin-bottom: 1rem;
                    :first-child {
                        font-weight: 600;
                        display: block;
                        width: 35%;
                    }
                    :nth-child(2){
                        padding-left: 2rem;
                    }
                }
            }

            .family-member-images {
                display: flex;
                gap: 1rem;
                padding: 1.5rem;
                overflow-x: auto;
                .family-member-image {
                    min-height: 8rem;
                    min-width: 8rem;
                    border-radius: 50%;
                    background-color: darkgrey;
                }
            }

        }
    }



    .overview-container {
        padding: 1.5rem;
        width: 100%;
        .overview-dashboard {
            height: auto;
            padding: 1rem 0;
            width: 100%;
            background-color: rgba(9, 120, 68, 0.2);
            border-radius: 1.5rem;
            border: darkgreen 0.01rem solid;
            border-radius: 1rem;
            display: flex;
            justify-content: space-around;
            .overview-dashboard-left {
                height: fit-content;
                width: fit-content;
                margin: auto 0;
            }
            .overview-dashboard-family-member {
                text-align: center;
                background-color: white;
                height: fit-content;
                padding: 0.5rem 2rem;
                margin: auto 0;
                line-height: 0.5rem;
                border-radius: 1rem;
                p {
                    font-weight: 600;
                }
                :nth-child(3){
                    color: #097844;
                    font-size: 2rem;
                    font-weight: 800;
                }
            }
        }
        .activities-list {
            margin-top: 1rem;
            padding: 1rem;
            border: 0.001rem silver solid;
            border-radius: 1rem;
            .activity-card {
                // border-left: #0EA5E9 solid 0.5rem;
                margin-top: 1rem;
                padding-left: 0.5rem;
                // height: 32rem;
                overflow-y: auto;
                border-top-left-radius: 1rem;
                border-bottom-left-radius: 1rem;
            }
        }
    }
}