.camera-container {
    position: fixed;
    top: 5rem;
    left: 30rem;
    margin: auto;
    z-index: 100;
    background-color: rgb(43, 42, 42);
    box-shadow: 2px 2px 9px 2px silver;
    border-radius: 0.5rem;
    padding: 0 0 1rem 0;
    .captured-btns {
        display: flex;
        justify-content: center;
        gap: 1rem;
        font-weight: 500;
    }
}