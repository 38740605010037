@import '../../../styles/variables';

// Variables
$primary-color: #2c4b71;
$primary-hover: #3a5f8f;
$border-color: #4a6285;
$text-color: #e5ecfd;
$placeholder-color: rgba($text-color, 0.5);
$disabled-color: rgba(255, 255, 255, 0.25);
$disabled-bg: rgba(0, 0, 0, 0.04);
$shadow-color: rgba(0, 0, 0, 0.2);
$transition-duration: 0.2s;

.filter-container {
  position: relative;
  display: inline-block;
}

.filter-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacing-sm;
  background-color: $background-secondary;
  border: 1px solid $border-color;
  border-radius: $border-radius-md;
  cursor: pointer;
  transition: all $transition-normal;
  min-width: 40px;
  min-height: 40px;
  color: $text-primary;

  &:hover {
    background-color: $background-tertiary;
    border-color: $primary-color;
  }

  &.active {
    background-color: rgba($primary-color, 0.1);
    border-color: $primary-color;
    color: $primary-color;
  }

  &.has-filters {
    color: $primary-color;
  }

  .filter-icon {
    font-size: $font-size-lg;
    transition: transform $transition-normal;

    &.active {
      transform: rotate(180deg);
    }
  }

  .filter-count {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: $primary-color;
    color: $text-light;
    font-size: $font-size-xs;
    min-width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    box-shadow: $shadow-sm;
  }
}

.filter-popover {
  position: absolute;
  top: calc(100% + $spacing-sm);
  right: 0;
  width: 320px;
  background-color: $background-secondary;
  border: 1px solid $border-color;
  border-radius: $border-radius-lg;
  padding: $spacing-md;
  box-shadow: $shadow-lg;
  z-index: 1000;
  animation: slideIn 0.2s ease;

  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing-md;
    padding-bottom: $spacing-sm;
    border-bottom: 1px solid $border-color;

    .filter-title {
      font-size: $font-size-md;
      font-weight: 600;
      color: $text-primary;
    }

    .clear-filters {
      color: $error-color;
      font-size: $font-size-sm;
      cursor: pointer;
      transition: opacity $transition-fast;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .filter-section {
    margin-bottom: $spacing-md;

    &:last-child {
      margin-bottom: 0;
    }

    .filter-label {
      display: block;
      font-weight: 500;
      margin-bottom: $spacing-xs;
      color: $text-primary;
      font-size: $font-size-sm;
    }

    .date-range {
      display: flex;
      gap: $spacing-sm;
      margin-bottom: $spacing-sm;

      .date-input {
        flex: 1;

        label {
          display: block;
          font-size: $font-size-xs;
          margin-bottom: $spacing-xs;
          color: $text-secondary;
        }

        input {
          width: 100%;
          padding: $spacing-sm;
          border: 1px solid $border-color;
          border-radius: $border-radius-md;
          background-color: $background-secondary;
          color: $text-primary;
          font-size: $font-size-sm;
          transition: all $transition-normal;

          &:hover {
            border-color: $primary-color;
          }

          &:focus {
            border-color: $primary-color;
            box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
            outline: none;
          }

          &::-webkit-calendar-picker-indicator {
            cursor: pointer;
            opacity: 0.7;
            transition: opacity $transition-fast;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }

    .filter-select {
      width: 100%;
      padding: $spacing-sm $spacing-xl $spacing-sm $spacing-md;
      border: 1px solid $border-color;
      border-radius: $border-radius-md;
      background-color: $background-secondary;
      color: $text-primary;
      font-size: $font-size-sm;
      transition: all $transition-normal;
      cursor: pointer;
      appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M2.5 4.5L6 8L9.5 4.5' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right $spacing-md center;

      &:hover {
        border-color: $primary-color;
      }

      &:focus {
        border-color: $primary-color;
        box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
        outline: none;
      }

      option {
        padding: $spacing-sm;
      }
    }

    .filter-chips {
      display: flex;
      flex-wrap: wrap;
      gap: $spacing-xs;

      .filter-chip {
        padding: $spacing-xs $spacing-sm;
        background-color: $background-tertiary;
        border-radius: $border-radius-sm;
        font-size: $font-size-xs;
        color: $text-primary;
        display: flex;
        align-items: center;
        gap: $spacing-xs;
        transition: all $transition-fast;
        cursor: pointer;

        &:hover {
          background-color: rgba($error-color, 0.1);
          color: $error-color;
        }

        .remove-chip {
          display: flex;
          align-items: center;
          padding: 2px;
          border-radius: 50%;
          
          &:hover {
            background-color: rgba($error-color, 0.2);
          }
        }
      }
    }
  }

  .filter-actions {
    display: flex;
    justify-content: flex-end;
    gap: $spacing-sm;
    margin-top: $spacing-md;
    padding-top: $spacing-md;
    border-top: 1px solid $border-color;
  }
}

// Responsive Design
@media (max-width: 768px) {
  .filter-popover {
    width: calc(100vw - #{$spacing-md * 2});
    max-width: 320px;
    right: -$spacing-sm;
  }

  .date-range {
    flex-direction: column;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-$spacing-sm);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}