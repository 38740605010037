.feature-input {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .feature-row {
    display: flex;
    gap: 1rem;
    align-items: center;

    input {
      padding: 0.5rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      flex: 1;
    }

    button {
      padding: 0.5rem 1rem;
      background-color: #ff4d4f;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #ff7875;
      }
    }
  }

  > button {
    align-self: flex-start;
    padding: 0.5rem 1rem;
    background-color: #1890ff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #40a9ff;
    }
  }
}
