// Variables
@import '../../../styles/variables';

.search-bar-container {
  position: relative;
  display: flex;
  align-items: center;
  background-color: $background-secondary;
  border: 1px solid $border-color;
  border-radius: $border-radius-md;
  transition: all $transition-normal;
  padding: $spacing-sm $spacing-xl;
  gap: $spacing-sm;

  &.focused {
    border-color: $primary-color;
    box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
  }

  &.disabled {
    background-color: $background-tertiary;
    cursor: not-allowed;

    input {
      cursor: not-allowed;
    }
  }

  .search-bar-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    min-height: 24px;
  }

  input {
    flex: 1;
    background: none;
    border: none;
    outline: none;
    color: $text-primary;
    font-size: $font-size-md;
    padding: 0;
    min-width: 0;

    &::placeholder {
      color: $text-secondary;
    }

    &:disabled {
      color: $text-secondary;
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;

    .loader-spinner {
      width: 1rem;
      height: 1rem;
      border: 2px solid rgba($text-primary, 0.3);
      border-top-color: $text-primary;
      border-radius: 50%;
      animation: spin 0.8s linear infinite;
    }
  }

  .clear-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: none;
    border: none;
    border-radius: 50%;
    color: $text-primary;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity $transition-normal ease;

    &:hover {
      opacity: 1;
      background-color: rgba($text-primary, 0.1);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
    }
  }
}

// Responsive Design
@media (max-width: 768px) {
  .search-bar-container {
    padding: $spacing-xs $spacing-md;

    input {
      font-size: $font-size-sm;
    }

    .search-bar-icon,
    .clear-button {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}