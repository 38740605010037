body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title{
  color: #097844;
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0rem;
    text-align: center;
}

.title-left{
  color: #097844;
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0rem;
    text-align: start;
}
