.EdTechApp {
  position: fixed;
  // min-height: 100vh;
  width: 100%;
  // background-color: #ECF2F7;

  .side-nav {
    width: 250px;
    background-color: #013f22;
    position: fixed;
    left: 0;
    top: 4rem;
    bottom: 0;
    padding-top: 0;
    color: white;
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1);

    .nav-item {
      display: flex;
      align-items: center;
      padding: 1rem 1.5rem;
      gap: 1rem;
      cursor: pointer;
      transition: background-color 0.2s;

      img {
        width: 1.5rem;
        height: 1.5rem;
        filter: brightness(0) invert(1);
      }

      span {
        font-size: 1rem;
        font-weight: 500;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      &.active {
        background-color: rgba(255, 255, 255, 0.15);
      }
    }

    .logo-container {
      padding: 0.5rem;
      text-align: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      img {
        width: 120px;
        height: auto;
      }
    }
  }

  .main-content {
    // margin-top: 4rem;
    // display: flex;
    padding: 0;
    flex: 1;
    height: 100vh;
    // width: 100;

    .dashboard-cards {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 1.5rem;
      margin-bottom: 2rem;

      .card {
        background-color: white;
        border-radius: 0.5rem;
        padding: 1.5rem;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

        h4 {
          color: #666;
          font-size: 0.875rem;
          margin-bottom: 0.5rem;
        }

        .number {
          font-size: 2rem;
          font-weight: 600;
          color: #013f22;
        }
      }
    }
  }

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
