// Variables
$primary-color: #e5ecfd;
$secondary-color: #28C670;
$background-color: #2c4b71;
$border-color: #4a6285;
$error-color: #ff4d4f;
$focus-color: #44b4ff;
$disabled-color: rgba(255, 255, 255, 0.25);
$transition-duration: 0.2s;

// Mixins
@mixin focus-effect {
  border-color: $focus-color;
  box-shadow: 0 0 0 2px rgba($focus-color, 0.2);
}

.text-area-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .text-area-label {
    font-weight: 600;
    color: $primary-color;
    display: flex;
    align-items: center;
    gap: 0.25rem;

    .required {
      color: $error-color;
    }
  }

  .text-area-form {
    position: relative;
    transition: all $transition-duration ease;

    textarea {
      width: 100%;
      padding: 0.75rem 1rem;
      font-size: 0.95rem;
      line-height: 1.5;
      color: $primary-color;
      background-color: $background-color;
      border: 1px solid $border-color;
      border-radius: 0.375rem;
      transition: all $transition-duration ease;
      outline: none;
      resize: vertical;
      min-height: 6rem;
      font-family: inherit;

      &::placeholder {
        color: rgba($primary-color, 0.5);
      }

      &:hover:not(:disabled) {
        border-color: $focus-color;
      }

      &:disabled {
        background-color: rgba($background-color, 0.8);
        color: $disabled-color;
        cursor: not-allowed;
        resize: none;
      }
    }

    &.focused:not(.error) textarea {
      @include focus-effect;
    }

    &.error textarea {
      border-color: $error-color;
      
      &:focus {
        box-shadow: 0 0 0 2px rgba($error-color, 0.2);
      }
    }

    &.disabled textarea {
      background-color: rgba($background-color, 0.8);
      color: $disabled-color;
      cursor: not-allowed;
    }

    .char-count {
      position: absolute;
      right: 0.75rem;
      bottom: 0.75rem;
      font-size: 0.8rem;
      color: rgba($primary-color, 0.6);
      pointer-events: none;
      background-color: rgba($background-color, 0.8);
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
    }
  }

  .error-message {
    font-size: 0.875rem;
    color: $error-color;
    margin-top: 0.25rem;
  }
}

// Responsive Design
@media (max-width: 768px) {
  .text-area-container {
    .text-area-form {
      textarea {
        padding: 0.625rem 0.875rem;
        font-size: 0.9rem;
        min-height: 5rem;
      }

      .char-count {
        font-size: 0.75rem;
        padding: 0.2rem 0.4rem;
      }
    }

    .error-message {
      font-size: 0.8rem;
    }
  }
}