.text-editor{
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
    text-align: start;
  }

  :root {
    --line-height: 30px; /* Set the default line height */
  }

  .ql-snow.ql-toolbar {
    display: flex;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    justify-content: center;
  }

  .ql-editor {
    min-height: 18em;
  }

  .ql-container {
    background-color: #f9f9f9; /* Optional: Set a background color */
    background-image: radial-gradient(circle, #c7c5c5 1px, transparent 1px);

    background-size: 20px 30px; /* Adjust spacing of dots */
    font-size: 1rem;
    line-height: 31px; 
    // line-height: 16px; /* Increase this value to add space between lines */

   /* Define consistent padding based on the line height */
  //  padding: calc(var(--line-height) * 2.5) calc(var(--line-height) * 6.25);
  
  //  /* Box-sizing to include padding in width/height calculation */
  //  box-sizing: border-box;
   
   color: #222;
   transition: color 0.5s ease;
   
   /* Optimized font styling for readability */
  //  font-size: calc(var(--line-height) * 0.55); /* 2/3 of line height */
   font-weight: 500; /* Regular weight */
   font-family: 'Geomanist', sans-serif; /* Ensure fallback font */
   letter-spacing: 0.02em; /* Tighter letter-spacing */
   
   /* Set line-height based on variable */
   line-height: calc(var(--line-height) *1);

   
    p{
      padding: 3px;
      margin: 0px; 
    }
  }


  .line {
    .ql-container {
      background-color: #f9f9f9; /* Optional: Set a background color */
      background-image: linear-gradient(to bottom, #000 1px, transparent 1px);
      font-size: 1rem;
      background-size: 100% 24px; /* Adjust line height spacing */
      line-height: 24px; 
    }
  }