.notification-ack-pop-container-parent {
    width:100vw;
    height:100vh;
    
    position:fixed;

    .notification-ack-pop-container {
        // position: fixed;
        min-height: 20rem;
        height: auto;
        width: 45vw;
        background-color: white;
        box-shadow: grey 2px 9px 12px 2px;
        margin: auto;
        margin-top: 10rem;
        border-radius: 0.5rem;
        padding: 1rem;
        display: flex;
        position: absolute;
        left: 30%;
        border: 0.01rem solid silver;
        .image-container {
            min-height: 100%;
            width: 40%;
            border: grey 1px solid;
            .camera-name {
                border: grey 1px solid;
                height: 3rem;
                width: 100%;
                text-align: center;
                line-height: 3rem;
                font-weight: 500;
            }
            .image {
                img {
                    height: 100%;
                    width: 100%;
                }
            }
        }
        .message-container {
            min-height: 100%;
            width: 60%;
            border: grey 1px solid;
            padding: 1rem;
            .title {
                border-bottom: grey 1px solid;
                height: 3rem;
                width: 100%;
                text-align: center;
                line-height: 3rem;
                font-weight: 500;
                font-size: large;
                margin-bottom: 1rem;
            }
            .message-title {
                display: flex;
                :first-child {
                    font-weight: 500;
                    // width: 7rem;
                    // display: block;
                }
            }
            .respond-btn-div {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}