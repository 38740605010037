.success-pop-up {
    height: 60vh;
    width: 40%;
    background-color: white;
    position: absolute;
    top: 7rem;
    margin: auto;
    box-shadow: 2px 2px 8px 2px rgb(161, 161, 161);
    border-radius: 0.2rem;
    text-align: center;
    display: flex;
    .camera-success-pop-up-content {
        width: fit-content;
        margin: auto;
        .message {
            width: fit-content;;
            margin-bottom: 1rem;
        }
        .btn {
            width: fit-content;
            margin: auto;
        }
        .close-btn {
            width: fit-content;
            margin: auto;
            margin-top: 1rem;
            &:hover {
                cursor: pointer;
                color: blue;
            }
        }
    }
}