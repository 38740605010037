.subscription-management {
  padding: 0.4rem 0.8rem;
  &.blur-bg {
    filter: blur(1rem);
    background: rgba(207, 207, 207, 0.244);
    pointer-events: none;
  }

  .visitors-list-header {
    display: flex;
    justify-content: space-between;

    .visitors-list-header-left {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .visitors-list-header-title {
            font-size: 1.4rem;
            font-weight: 500;
        }
        .visitors-list-header-update {
            background-color: #f1fffa;
            height: auto;
            padding: 0.2rem 0.7rem;
            line-height: 1rem;
            margin: auto 0.5rem;
            border-radius: 1rem;
            font-size: 0.8rem;
            color: green;
        }
    }

}


  .exams{
    margin-top: 1rem;
    border: 1px solid rgba(0,0,0,0.12);
    border-radius: 0.4rem;
    padding: 0.8rem;

    .exams-tab{
      margin: 0.8rem 0;
      display: flex;
      align-items: center;
      gap: 0.8rem;

      span{
        padding: 0.4rem 0.8rem;
        cursor: pointer;
        border-radius: 0.4rem;
        border: 1px solid #00000026;

        &.selected{
          background: #02720d;
          color: #fff;
        }
      }
    }
  }

  .plans-container {
    margin-top: 2rem;
    display: flex;
    gap: 0.8rem;
    padding: 0.8rem;
    flex-wrap: wrap;

    .card-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 0px 2.67px 13.36px 0px #00000026;
      border-radius: 0.4rem;
      padding: 0.8rem;
      gap: 0.8rem;
      min-width: 30%;
    .content{
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      justify-content: center;
      .image{
        width: 6rem;

        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
        p{
            font-weight: 500;
        }
    }
    .access-content{
      padding: 0.4rem 0.8rem;
      li{
        margin-bottom: 0.8rem;
        list-style: none;
        display: flex;
        align-items: center;
        gap: 0.4rem;
        
      }
      
      border-bottom: 1px solid rgba(0,0,0,0.12);
    }
      .plan-pricing{
        display: flex;
        align-items: baseline;

        span{
            color: #AEAEAE;
        }
      }

      .plan-btn {
        background: #02720d;
            padding: 0.8rem 2rem;
        border-radius: 2rem;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .add-plan{
      border-radius: 0.4rem;
      padding: 1rem;
      border: 1px solid #02720d;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;
            min-width: 24%;
      
        color: #02720d;

      .add-icon{
        border: 1px solid #02720d;
        border-radius: 0.4rem;
        padding: 1rem;
        cursor: pointer;
      }
    }
  }
}
