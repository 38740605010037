.question-viewer {
  width: 98%;
  margin: 0 auto;
  padding: 0.8rem;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    margin: 0 1rem;

    .back {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      cursor: pointer;
    }
    .switch-question {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      p {
        background: rgb(201, 199, 199);
        padding: 0.4rem 0.8rem;
        border-radius: 0.4rem;
      }
      svg{
        cursor: pointer;
      }
    }
    .action {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      span{
        display: flex;
        align-items: center;
        gap: 0.4rem;
        cursor: pointer;
        padding: 0.2rem 1rem;
        border-radius: 0.4rem;

      }
    }
    .save{
      color: #fff;
          background: #008d4b;
          
    }
    .delete{
      color: red;
    }
    .reset{
      color: #097844;
          border: 1px solid #097844;
    }
  }

  .questionViewer-container {
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 1rem;
    border-radius: 0.4rem;

    .tags {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      margin-bottom: 0.8rem;
      span {
        font-size: 0.8rem;
      }
    }
    .question-content {
      font-size: 1rem;
      width: 80%;
      font-weight: bold;

      ul{
        margin: 1rem;
        padding-left: 2rem;
        font-weight: 400;
        li{
          list-style-type: upper-alpha;
        }
      }
      .correct{
        color: #440978;
        margin-top: 0.8rem;
      }
    }
  }

  .hint-explanation-section {
    padding: 1rem 0;
    border-radius: 0.4em;

    .accordion {
      border: 0.94px solid #ece6f2;
      box-shadow: 0px 0.94px 1.88px 0px #44097826;
      border-radius: 0.4rem;
      margin-top: 0.8rem;

      div {
        padding: 0.8rem;
      }
    }

    .hints-title {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 0.8rem;
      color: #440978;
      font-weight: 600;
      padding: 0.8rem;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 0.8rem;
    }
  }

  .video-explanation{
    padding: 1rem 0;
    border-radius: 0.4em;
    .accordion {
      border: 0.94px solid #ece6f2;
      box-shadow: 0px 0.94px 1.88px 0px #44097826;
      border-radius: 0.4rem;
      margin-top: 0.8rem;

      

      .video-title {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 0.8rem;
      color: #440978;
      font-weight: 600;
      padding: 0.8rem;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 0.8rem;
    }
    .explanation{
        display: flex;
        overflow-x: scroll;
        gap: 0.8rem;
        padding: 0.8rem;
        background-color: white;

        .video{
          width: 24%;
          border-radius: 0.4rem;
          position: relative;
          video{
            object-fit: cover;
            width: 100%;
            border-radius: 0.4rem;
          }
          svg{
            position: absolute;
            color: #efefef;
            left: 45%;
            top: 40%;
            font-size: 2rem;
            cursor: pointer;
          }
        }

        .upload{
          width: 24%;
          border: 1px solid #008d4b;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.4rem;

          .upload-button{
            text-align: center;
            color: #008d4b;
            cursor: pointer;
          }
        }
        
      }
    }
  }
  .tags-section{
    padding: 1rem 0;
    border-radius: 0.4em;
    .accordion {
      border: 0.94px solid #ece6f2;
      box-shadow: 0px 0.94px 1.88px 0px #44097826;
      border-radius: 0.4rem;
      margin-top: 0.8rem;

      div {
        padding: 0.4rem 0.8rem ;
      }

      .tags-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.8rem;
      color: #440978;
      font-weight: 600;
      padding: 0.8rem;
      background: #ECE6F2;
      border: none;
      cursor: pointer;
      font-size: 0.8rem;
    }
     .explanation{
      display: flex;
      align-items: center;
      gap: 0.8rem;
      flex-wrap: wrap;

      span{
        display: flex;
      align-items: center;
      gap: 0.2rem;
      font-size: 0.8rem;
      cursor: pointer;
      padding: 0.4rem;
      background: #ECE6F2;
      border-radius: 2rem;
      }
    }

    .all-tags{
      display: flex;

      .unselected{
        background: none;
        border: 1px solid black;
      }
    }
    }
  }
  .topics-section{
    padding: 1rem 0;
    border-radius: 0.4em;
    .accordion {
      border: 0.94px solid #ece6f2;
      box-shadow: 0px 0.94px 1.88px 0px #44097826;
      border-radius: 0.4rem;
      margin-top: 0.8rem;

      div {
        padding: 0.4rem 0.8rem ;
      }

      .topic-title {
        background: #ECE6F2;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.8rem;
      color: #440978;
      font-weight: 600;
      padding: 0.8rem;
      border: none;
      cursor: pointer;
      font-size: 0.8rem;
    }

    .explanation{
      display: flex;
      align-items: center;
      gap: 0.4rem;
      flex-wrap: wrap;

      span{
        display: flex;
      align-items: center;
      gap: 0.2rem;
      font-size: 0.8rem;
      cursor: pointer;
      padding: 0.4rem;
      background: #ECE6F2;
      border-radius: 2rem;
      }
    }

    .all-tags{
      display: flex;

      .unselected{
        background: none;
        border: 1px solid black;
      }
    }
    }
  }


}
