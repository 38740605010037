@import '../../../styles/variables';

// Variables
$primary-color: $primary-color;
$secondary-color: $secondary-color;
$background-color: $background-secondary;
$border-color: $border-color;
$error-color: $error-color;
$camera-btn-color: $secondary-color;
$transition-duration: $transition-normal;

// Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin hover-effect {
  transition: all $transition-duration ease;
  &:hover {
    transform: translateY(-1px);
    opacity: 0.9;
  }
}

.file-uploader-container {
  position: relative;
  
  .file-uploader-label {
    font-weight: 600;
    color: $text-primary;
    margin-bottom: $spacing-xs;
    font-size: $font-size-sm;
    display: flex;
    align-items: center;
    gap: $spacing-xs;

    .required {
      color: $error-color;
    }
  }

  .file-uploader-section-parent-div {
    padding: $spacing-lg;
    border: 1px solid $border-color;
    border-radius: $border-radius-md;
    color: $text-primary;
    background-color: $background-color;
    transition: all $transition-duration ease;

    &.drag-active {
      border-color: $primary-color;
      background-color: $primary-light;
      box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
    }

    &:focus-within {
      border-color: $primary-color;
    }

    .file-uploader-section {
      @include flex-center;
      position: relative;
      min-height: 4rem;
      border: 2px dashed $border-color;
      border-radius: $border-radius-md;
      padding: $spacing-lg;
      cursor: pointer;
      transition: all $transition-duration ease;

      &:hover {
        border-color: $primary-color;
        background-color: rgba($primary-color, 0.05);
      }

      input[type="file"] {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;

        &:focus + .upload-text {
          outline: 2px solid $primary-color;
          outline-offset: 2px;
        }
      }

      .upload-text {
        font-size: $font-size-sm;
        color: $text-secondary;
        text-align: center;
        pointer-events: none;
      }
    }

    .file-info {
      margin-top: $spacing-md;
      padding: $spacing-sm;
      background-color: rgba($background-color, 0.5);
      border-radius: $border-radius-md;
      font-size: $font-size-sm;

      .file-name {
        font-weight: 500;
        margin-right: $spacing-sm;
      }

      .file-size {
        color: rgba($text-primary, 0.7);
      }
    }

    .error-message {
      margin-top: $spacing-xs;
      color: $error-color;
      font-size: $font-size-sm;
    }

    .capture-from-camera {
      margin-top: $spacing-md;
      text-align: center;

      &::before {
        content: 'or';
        display: block;
        margin-bottom: $spacing-sm;
        color: rgba($text-primary, 0.7);
        font-size: $font-size-sm;
      }

      .camera-btn {
        @include hover-effect;
        display: inline-flex;
        align-items: center;
        gap: $spacing-sm;
        padding: $spacing-sm $spacing-md;
        border: 1px solid $border-color;
        border-radius: $border-radius-md;
        cursor: pointer;
        background-color: $camera-btn-color;
        transition: all $transition-duration ease;

        &:hover {
          background-color: lighten($camera-btn-color, 5%);
          border-color: $primary-color;
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
        }

        img {
          width: 1.5rem;
          height: 1.5rem;
          opacity: 0.8;
          transition: opacity $transition-duration ease;
        }

        span {
          font-size: $font-size-sm;
          font-weight: 500;
          color: $text-primary;
        }
      }
    }

    .upload-button {
      margin-top: $spacing-md;

      button {
        @include hover-effect;
        width: 100%;

        &:disabled {
          opacity: 0.6;
          cursor: not-allowed;
          transform: none;
        }
      }
    }
  }
}

// Camera Modal Styles
.camera-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($text-primary, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .camera-content {
    background-color: $background-color;
    padding: $spacing-lg;
    border-radius: $border-radius-lg;
    max-width: 90%;
    max-height: 90vh;
    overflow: hidden;
    position: relative;
    
    .camera-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $spacing-md;
      
      .close-btn {
        background: none;
        border: none;
        color: $text-secondary;
        cursor: pointer;
        padding: $spacing-sm;
        font-size: $font-size-lg;
        
        &:hover {
          color: $primary-color;
        }
      }
    }
    
    .camera-preview {
      width: 100%;
      height: auto;
      border-radius: $border-radius-md;
      overflow: hidden;
      
      video {
        width: 100%;
        height: auto;
      }
    }
    
    .camera-controls {
      display: flex;
      gap: $spacing-md;
      justify-content: center;
      margin-top: $spacing-md;
    }
  }
}

// Responsive Design
@media (max-width: 768px) {
  .file-uploader-container {
    .file-uploader-section-parent-div {
      padding: $spacing-sm;

      .file-uploader-section {
        min-height: 80px;
      }

      .camera-btn {
        padding: $spacing-sm $spacing-md;
        
        img {
          width: 1.25rem;
          height: 1.25rem;
        }
        
        span {
          font-size: $font-size-xs;
        }
      }
    }
  }
  
  .camera-modal {
    .camera-content {
      padding: $spacing-md;
      width: 95%;
      
      .camera-controls {
        flex-wrap: wrap;
      }
    }
  }
}