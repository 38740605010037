
.generic-table-container {
    // width: 94%;
    position: relative;
    // border: rgb(236, 236, 236) 0.01rem solid;
    padding: 0 0rem;
    // margin-top: 1rem;
    height: 90vh;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 
    table {
        width: 100%;
        border-collapse: collapse; 
        font-size: 1rem;
        
        thead {
            color: #000000b8;
            font-size: 1.1rem;
            font-weight: 500;
            // border-bottom: 0.01rem solid rgb(206, 206, 206);
            // background-color: rgb(233, 231, 231);
            position: sticky;

            td {
                // padding: 1.2rem 1rem;
                padding: 1rem 1rem;
                padding-bottom: 0.5rem;
                position: sticky;
            }
        }

        tr {
                // border: 0.5rem solid white;
                border-bottom: 0.5rem solid white;
                border-radius: 20px;
                // margin: 1rem;
                background-color: #ECF2F7;

                &:hover {
                    background-color: rgb(236, 247, 252);
                    cursor: pointer;
                    transition: ease-in-out 0.1s;
                }
            td {

                padding: .9rem 1rem;
                color: rgb(82, 82, 82);
            }
        }
    }
}