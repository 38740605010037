.visitors-list-header {
  display: flex;
  justify-content: space-between;

  .visitors-list-header-left {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .visitors-list-header-title {
          font-size: 1.4rem;
          font-weight: 500;
      }
      .visitors-list-header-update {
          background-color: #f1fffa;
          height: auto;
          padding: 0.2rem 0.7rem;
          line-height: 1rem;
          margin: auto 0.5rem;
          border-radius: 1rem;
          font-size: 0.8rem;
          color: green;
      }
  }

}

.subscription-card-container {
    display: flex;
    padding: 0.4rem 0.8rem;
    margin-top: 1rem;
    gap: 0.8rem;
    flex-wrap: wrap;

    .cards {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 0.8rem;
      border: 1px solid rgba(0, 0, 0, 0.42);
      padding: 0.8rem;
      position: relative;
      gap: 0.8rem;

      .highlighter {
        position: absolute;
        width: 4px;
        height: 2.5rem;
        background-color: rgba(80, 150, 172, 1);
        left: 0;
        top: 20px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      .title{
        border-bottom: 0.8px solid #FED8CC;
        
        p{
            
            color: gray;
        }
      }
      .count{
        color: #2962FF;
      }
      &:last-child{
        
        .count{
            color: black;
        }
        background-color: #E6F6F4;
      }
      
    }
  }

  .subjectContainer{
    display: flex;
    padding: 0.4rem 0.8rem;
    margin-top: 1rem;
    gap: 0.8rem;
    flex-wrap: wrap;
}

.overview-container{
.add-plan{
    border-radius: 0.4rem;
    margin: 1rem;
    border: 1px solid #02720d;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 20%;
    height: 4rem;
    color: #02720d;

    .add-icon{
      border: 1px solid #02720d;
      border-radius: 0.4rem;
      padding: .2rem;
      cursor: pointer;
    }
  }

}