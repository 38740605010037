@import '../../../styles/variables';

.drop-down-container {
    .text-input-label {
        font-weight: 600;
        color: $text-primary;
        margin-bottom: $spacing-xs;
        font-size: $font-size-sm;
    }
    
    .text-input-form {
        margin-top: $spacing-sm;
        background-color: $background-secondary;
        border-radius: $border-radius-md;
        box-shadow: $shadow-sm;
        
        select {
            border-radius: $border-radius-md;
            border: 1px solid $border-color;
            transition: all $transition-normal;
            outline: none;
            text-align: left;
            padding: $spacing-sm $spacing-md;
            color: $text-primary;
            cursor: pointer;
            width: 100%;
            font-size: $font-size-md;
            background-color: $background-secondary;
            
            &:hover:not(:disabled) {
                border-color: $primary-color;
            }
            
            &:focus {
                border-color: $primary-color;
                box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
            }
            
            &:disabled {
                background-color: $background-tertiary;
                cursor: not-allowed;
            }
            
            option {
                padding: $spacing-sm;
                background-color: $background-secondary;
                color: $text-primary;
                
                &:hover {
                    background-color: $primary-light;
                }
            }
        }
    }

    .multiValues-chip-container {
        display: flex;
        gap: $spacing-xs;
        margin-top: $spacing-xs;
        flex-wrap: wrap;
        
        .multiValues-chip {
            border: 1px solid $border-color;
            padding: $spacing-xs $spacing-sm;
            font-size: $font-size-sm;
            color: $text-secondary;
            display: flex;
            align-items: center;
            border-radius: $border-radius-sm;
            background-color: $background-tertiary;
            
            img {
                height: 1.1rem;
                margin-left: $spacing-xs;
                cursor: pointer;
                transition: opacity $transition-fast;
                
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}