// Color Palette
$primary-color: #1a73e8;
$primary-light: #e8f0fe;
$primary-dark: #1557b0;

$secondary-color: #5f6368;
$secondary-light: #f1f3f4;
$secondary-dark: #3c4043;

$background-primary: #f8f9fa;
$background-secondary: #ffffff;
$background-tertiary: #f1f3f4;

$text-primary: #202124;
$text-secondary: #5f6368;
$text-light: #ffffff;

$border-color: #dadce0;
$error-color: #d93025;
$success-color: #1e8e3e;
$warning-color: #f29900;

// Spacing
$spacing-xs: 0.25rem;
$spacing-sm: 0.5rem;
$spacing-md: 1rem;
$spacing-lg: 1.5rem;
$spacing-xl: 2rem;

// Border Radius
$border-radius-sm: 4px;
$border-radius-md: 8px;
$border-radius-lg: 12px;

// Shadows
$shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
$shadow-md: 0 2px 4px rgba(0, 0, 0, 0.1);
$shadow-lg: 0 4px 6px rgba(0, 0, 0, 0.1);

// Typography
$font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
$font-size-xs: 0.75rem;
$font-size-sm: 0.875rem;
$font-size-md: 1rem;
$font-size-lg: 1.125rem;
$font-size-xl: 1.25rem;

// Transitions
$transition-fast: 150ms ease;
$transition-normal: 250ms ease;
$transition-slow: 350ms ease;
