.taashpatti_sidenav__container {
    min-width: 6rem;
    min-height: auto;
    background-color: #013f22;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: end;
   
  }
  
  
  
  .sidenav__routes {
    display: flex;
    flex-direction: column;
  }
  
  .sidenav__routes-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.8rem 0;
  }
  
  .sidenav__routes-link:hover:not(.selected-route) {
    width: 100%;
    background-color: rgba(172, 171, 171, 0.2);
  }
  
  .selected-route {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .selected-border {
    left: 0;
    top: 0;
    height: 100%;
    width: 0.3rem;
    background-color: rgb(123, 3, 35);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .sidenav__route-image {
    width: 30px;
  }
  
  .sidenav__route-name {
    color: white;
    font-size: 0.8rem;
    margin: 0;
  }
  .logout{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.8rem 0;
    &:hover{
      cursor: pointer;
      width: 100%;
      background-color: rgba(172, 171, 171, 0.2);
    }
    
  }

  .sidenav__route-logo-image{
    width: 3rem;
    padding-left: 1.7rem;
    padding-top: 1rem;
  }