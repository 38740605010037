.add-plan-container {
  position: absolute;
  top: 15%;
  left: 30%;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.4rem;
  width: 40%;

  
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    h3 {
      padding: 0.8rem;
    }
    .close {
      padding: 0.8rem;
      font-size: 3rem;
      cursor: pointer;
    }
  }

  .add-camera-form-row-1, 
  .add-camera-form-row-2,
  .add-camera-form-row-3,
  .add-camera-form-row-4
  {
      display: flex;
      gap: 2rem;
      margin-top: 2rem;
  }
  
  .image-container {
    padding: 0.8rem;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    .image {
      border: 1px solid rgba(0, 0, 0, 0.12);
      padding: 1rem;
      border-radius: 0.4rem;
      cursor: pointer;
    }
    .upload-button {
      border: 1px solid #02720d;
      padding: 0.4rem 1rem;
      color: #02720d;
      border-radius: 0.4rem;
      cursor: pointer;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    padding: 0.8rem;

    input,
    select {
      padding: 0.4rem;
      outline: none;
      border: 1px solid #c5b3d5;
      border-radius: 0.4rem;
    }
  }
  .price-valid {
    display: flex;
  }
  .content-access {
    padding: 0.8rem;
    margin: 1rem 0;

    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 0.8rem;

      thead {
        th {
          text-align: left;
          background: rgba(9, 120, 68, 0.1019607843);
          padding: 0.2rem 0.4rem;
          font-weight: 400;
        }
      }

      tbody {
        td {
          text-align: left;
          padding: 0.2rem 0.4rem;
          font-weight: 400;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }
        .permission-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .button {
            border-bottom: none;
            button {
              background: #fff;
              border: none;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #02720d;
            }
          }
          .input input {
            padding: 0.2rem;
            border-radius: 0.1rem;
          }
        }
      }
    }
  }

  .button {
    padding: 0.8rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span {
      background: #02720d;
      padding: 0.4rem 1.4rem;
      color: #fff;
      border-radius: 0.4rem;
      cursor: pointer;
    }
  }
}
