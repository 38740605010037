.login-container {
    display: flex;
    width: 100%;
    .login-banner-section {
        width: 40%;
        overflow: hidden;
        .pink-smoke {
            width: 40%;
            position: fixed;
            top: 0;
            left: 0;
        }
    }
    .login-form-section {
        width: 60%;
        overflow: hidden;
        height: 100vh;
        padding-left: 5rem;
        .green-smoke {
            width: 60%;
            height: 50%;
            position: fixed;
            bottom: 0;
            right: 0;
            z-index: 0;
        }
        .login-input-box {
            margin-top: 2rem;
        }

        .login-content-section {
            height: fit-content;
            width: fit-content;
            margin-top: 10%;
            .login-font-input {
                width: fit-content;
                border: 0.001rem solid grey;
                padding: 0.2rem 0.3rem;
                border-radius: 0.5rem;
                z-index: 10;
                margin-top: 0.5rem;
                display: flex;
                .input-icon {
                    img {
                        width: 1.5rem;
                        margin: 0.5rem;
                        height: 1.5rem;
                    }
                }
                .line-bar {
                    height: 2rem;
                    width: 0.01rem;
                    background-color: silver;
                    margin: auto 0.5rem auto 0.3rem;
                }
                input {
                    border: none;
                    background-color: none;
                    width: 25rem;
                    height: 2.5rem;
                    font-size: 1.1rem;
                    padding-left: 0.5rem;
                }
            }
        }

        .login-button {
            margin-top: 2rem;
        }
    }
}