@import '../../../styles/variables';

.drop-down-container {
  position: relative;
  width: 100%;

  .text-input-label {
    font-weight: 600;
    color: $text-primary;
    margin-bottom: $spacing-xs;
    font-size: $font-size-sm;
    display: flex;
    align-items: center;
    gap: $spacing-xs;

    .required {
      color: $error-color;
    }
  }

  .text-input-form {
    position: relative;
    width: 100%;

    select {
      width: 100%;
      padding: $spacing-sm $spacing-xl $spacing-sm $spacing-md;
      font-size: $font-size-md;
      color: $text-primary;
      background-color: $background-secondary;
      border: 1px solid $border-color;
      border-radius: $border-radius-md;
      cursor: pointer;
      transition: all $transition-normal;
      display: flex;
      align-items: center;
      gap: $spacing-sm;
      min-height: 2.5rem;
      box-shadow: $shadow-sm;

      &:hover {
        border-color: $primary-color;
      }

      &:focus {
        border: 0.01rem solid skyblue;
      }
    }

    select.placeholder {
      color: $text-secondary;
    }

    select.disabled {
      background-color: $background-tertiary;
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  .multiValues-chip-container {
    display: flex;
    gap: 0.2rem;
    margin-top: 0.2rem;
    flex-wrap: wrap;
    .multiValues-chip {
      border: silver 0.01rem solid;
      padding: 0.2rem 0.4rem;
      font-size: 0.8rem;
      display: flex;
    }
  }
}