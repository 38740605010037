.visitors-list-container {
    .visitors-list-header {
        display: flex;
        justify-content: space-between;

        .visitors-list-header-left {
            display: flex;
            .visitors-list-header-title {
                font-size: 1.4rem;
                font-weight: 500;
            }
            .visitors-list-header-update {
                background-color: #f1fffa;
                height: auto;
                padding: 0.2rem 0.7rem;
                line-height: 1rem;
                margin: auto 0.5rem;
                border-radius: 1rem;
                font-size: 0.8rem;
                color: green;
            }
        }

    }
}