// Variables
@import '../../../styles/variables';

// Mixins
@mixin focus-effect {
  border-color: $primary-color;
  box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
}

.text-input-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;

  .text-input-label {
    font-weight: 600;
    color: $text-primary;
    display: flex;
    align-items: center;
    gap: $spacing-xs;
    font-size: $font-size-sm;

    .required {
      color: $error-color;
    }
  }

  .text-input-form {
    position: relative;
    transition: all $transition-normal;

    input {
      width: 100%;
      padding: $spacing-sm $spacing-md;
      font-size: $font-size-md;
      line-height: 1.5;
      color: $text-primary;
      background-color: $background-secondary;
      border: 1px solid $border-color;
      border-radius: $border-radius-md;
      transition: all $transition-normal;
      outline: none;
      box-shadow: $shadow-sm;

      &::placeholder {
        color: $text-secondary;
      }

      &:hover:not(:disabled) {
        border-color: $primary-color;
      }

      &:focus {
        border-color: $primary-color;
        box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
      }

      &:disabled {
        background-color: $background-tertiary;
        cursor: not-allowed;
        opacity: 0.7;
      }
    }

    &.error {
      input {
        border-color: $error-color;
        
        &:focus {
          box-shadow: 0 0 0 2px rgba($error-color, 0.2);
        }
      }
    }

    .error-message {
      color: $error-color;
      font-size: $font-size-xs;
      margin-top: $spacing-xs;
    }

    .input-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: $text-secondary;
      transition: color $transition-fast;

      &.left {
        left: $spacing-sm;
        & + input {
          padding-left: $spacing-xl;
        }
      }

      &.right {
        right: $spacing-sm;
        & + input {
          padding-right: $spacing-xl;
        }
      }
    }
  }
}

// Responsive Design
@media (max-width: 768px) {
  .text-input-container {
    .text-input-form {
      input {
        padding: $spacing-xs $spacing-md;
        font-size: $font-size-sm;
      }

      .error-message {
        font-size: $font-size-xs;
      }
    }
  }
}