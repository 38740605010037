// Variables
$primary-color: #2c4b71;
$primary-hover: #3a5f8f;
$secondary-color: #4a6285;
$secondary-hover: #5a7396;
$danger-color: #ff4d4f;
$danger-hover: #ff7875;
$success-color: #28C670;
$success-hover: #36d67e;
$disabled-color: rgba(0, 0, 0, 0.25);
$disabled-bg: #e5ecfd;
$text-color: #e5ecfd;
$transition-duration: 0.2s;

// Mixins
@mixin button-base {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 0.375rem;
  font-weight: 500;
  cursor: pointer;
  transition: all $transition-duration ease;
  gap: 0.5rem;
  line-height: 1.5;
  outline: none;
  position: relative;
  white-space: nowrap;
  
  &:focus-visible {
    box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
  }
  
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

@mixin button-variant($bg-color, $hover-color) {
  background-color: $bg-color;
  color: $text-color;
  
  &:hover:not(:disabled) {
    background-color: $hover-color;
  }
  
  &:active:not(:disabled) {
    transform: translateY(1px);
  }
}

// Legacy button styles
.btn-legacy {
  @include button-base;
  text-align: center;
  
  &.small-btn-container {
    padding: .3rem 1.5rem;
    border-radius: 0.5rem;
    font-size: 1rem;
  }

  &.medium-btn-container {
    padding: .5rem 1.5rem;
    border-radius: 0.5rem;
    font-size: 1rem;
  }

  &.btn-container {
    padding: 1rem 3rem;
    border-radius: 0.5rem;
    font-size: 1.5rem;
  }
}

// Modern button styles
.btn {
  @include button-base;

  // Variants
  &--primary {
    @include button-variant($primary-color, $primary-hover);
  }

  &--secondary {
    @include button-variant($secondary-color, $secondary-hover);
  }

  &--danger {
    @include button-variant($danger-color, $danger-hover);
  }

  &--success {
    @include button-variant($success-color, $success-hover);
  }

  &--text {
    background: transparent;
    color: $primary-color;
    padding: 0;

    &:hover:not(:disabled) {
      color: $primary-hover;
      background: transparent;
    }
  }

  // Sizes
  &--small {
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
  }

  &--medium {
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }

  &--large {
    padding: 0.75rem 1.5rem;
    font-size: 1.125rem;
  }

  // Modifiers
  &--full-width {
    width: 100%;
  }

  &--loading {
    cursor: wait;
    
    .btn__text {
      opacity: 0.7;
    }
  }

  &--disabled {
    background-color: $disabled-bg;
    color: $disabled-color;
    
    &:hover {
      background-color: $disabled-bg;
      color: $disabled-color;
    }
  }

  // Elements
  &__icon {
    display: inline-flex;
    align-items: center;
    font-size: 1.2em;

    &--left {
      margin-right: 0.25em;
    }

    &--right {
      margin-left: 0.25em;
    }
  }

  &__loader {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 0.25rem;

    .loader-dot {
      width: 0.5rem;
      height: 0.5rem;
      background-color: currentColor;
      border-radius: 50%;
      animation: loader 1s infinite;

      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }
}

@keyframes loader {
  0%, 100% {
    opacity: 0.5;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
}