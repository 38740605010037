.unit-details-pop-up-container {
    background-color: white;
    position: absolute;
    height: 89vh;
    width: 30%;
    top: 0.05rem;
    right: 0rem;
    box-shadow: 2px 0px 10px rgba(66, 65, 65, 0.479);
    clip-path: inset(0px 0px 0px -10px);
    padding: 0.5rem 0.5rem;
    transform: translatex(-0%);
    transition: transform 5s ease-in-out;

    .camera-overview {
        background-color: rgba(9, 120, 68, 0.2);
        padding: 1rem;
        border-radius: 1rem;
        margin-top: 1rem;
    }
    .pop-up-title {
        color: #0EA5E9;
        text-decoration: underline;
        font-size: 1.1rem;
        font-weight: 600;
        width: fit-content;
        margin: 1rem auto auto auto;
    }

    .pop-up-overview {
        border: rgb(211, 211, 211) 0.001rem solid;
        border-radius: 0.7rem;
        margin: 1rem 0;
        padding: 1rem 1rem;
        .pop-up-overview-title {
            color: #0EA5E9;
            font-weight: 600;
            font-size: 1.2rem;
        }

        .pop-up-overview-message {
            :first-child {
                font-weight: 600;
            }
            :nth-child(2) {
                font-size: 0.9rem;
                color: #525252;
            }

            border-bottom: 0.001rem solid #b8b8b8;
            padding-bottom: 1rem ;
            margin-bottom: 1rem;
        }
        .pop-up-details {
            margin-top: 0.7rem;
            display: flex;
            :first-child {
                display: block;
                width: 35%;
                font-weight: 600;
                margin: 0rem 2rem 0 0;

            }
            :nth-child(2) {
                color: #525252;
            }
        }

    }
    .pop-up-file {
        border: rgb(211, 211, 211) 0.001rem solid;
        border-radius: 0.7rem;
        margin: 1rem 0;
        padding: 1rem 1rem;
        .pop-up-file-title {
            color: #0EA5E9;
            font-weight: 600;
            font-size: 1.2rem;
        }
        .pop-up-file-container {
            height: 3rem;
            width: auto;
            background-color: #E7F6FD;
            border-radius: 0.5rem;
            padding: 0.4rem 0 0.1rem 1rem;
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;
            .pop-up-file-details {
                display: flex;
                gap: 0.7rem;
            .pop-up-file-img {
                background-color: rgb(255, 255, 255);
                width: fit-content;
                padding: 0.2rem 0.5rem;
                height: fit-content;
                border-radius: 0.5rem;
                img {
                    margin-top: 0.3rem;
                }
            }
            .pop-up-file-name-size {
                font-size: small;
            }
            }
            .pop-up-file-name-download {
                margin: auto 1rem auto 0;
                img {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .close-btn {
        height: 1rem;
        width: 1rem;
        img {
            height: 2rem;
            width: 2rem;
            cursor: pointer;
        }
    }
}