.add-plan-container {
  position: absolute;
  top: 8%;
  left: 40%;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.4rem;
  text-align: start;
  padding: 1rem;

  
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    h3 {
      padding: 0.8rem;
    }
    .close {
      padding: 0.8rem;
      font-size: 3rem;
      cursor: pointer;
    }
  }

  .add-camera-form-row-1, 
  .add-camera-form-row-2,
  .add-camera-form-row-3,
  .add-camera-form-row-4
  {
      display: flex;
      gap: 2rem;
      margin-top: 2rem;
  }

  .image-container {
    padding: 0.8rem;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    .image {
      border: 1px solid rgba(0, 0, 0, 0.12);
      padding: 1rem;
      border-radius: 0.4rem;
      cursor: pointer;
    }
    .upload-button {
      border: 1px solid #02720d;
      padding: 0.4rem 1rem;
      color: #02720d;
      border-radius: 0.4rem;
      cursor: pointer;
    }
  }

}
