.pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-right: 1rem;
  }
  
  .pagination-button {
    background-color: rgb(40, 198, 112);
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
    transition: background-color 0.3s;
  
    &:hover {
      background-color: rgb(40, 198, 112);
    }
  
    &:disabled {
      background-color: #2c4b71;
      cursor: not-allowed;
    }
  }
  
  .pagination-info {
    margin: 0 10px;
    font-size: 16px;
  }
  
  .pagination-limit {
    display: flex;
    align-items: center;
    margin-left: 10px;
  
    select {
      margin-left: 5px;
      padding: 5px;
      border-radius: 4px;
      border: 1px solid #2c4b71;
    }
  }
  